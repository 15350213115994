<template>
  <a-layout class='index animated fadeIn'>
    <a-card>
      <a-form ref='form' :model='search' :style="{'marginBottom':'20px'}" :initialValues="search"
        :onFinish='pageSearchChange' layout='inline'>
        <a-form-item label="代理商" name='keywords'>
          <a-input v-model:value="search.keywords" style="width:250px" placeholder="请输入代理商名称、账号"></a-input>
        </a-form-item>
        <a-form-item label="代理商级别" name='level_id'>
          <a-select v-model:value="search.level_id" :style="{width:'180px'}" placeholder="全部">
            <a-select-option value="">
              全部
            </a-select-option>
            <a-select-option v-for='item in levelList' :key="item.id" :value="item.id">
              {{item.level_name}}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" htmlType='submit'>搜索</a-button>
        </a-form-item>
      </a-form>
      <a-table :pagination='false' :loading='listLoading' bordered rowKey='id' :columns='columns' :data-source='data'>
        <template v-slot:action="{ record }">
          <a-dropdown>
            <a class="ant-dropdown-link"> 操作
              <DownOutlined />
            </a>
            <template v-slot:overlay>
              <a-menu>
                <a-menu-item>
                  <a v-has:lookRecord="()=>recharge(record,'record')" href="javascript:;">充值记录</a>
                </a-menu-item>
                <a-menu-item>
                  <a v-has:agentRecharge="()=>recharge(record,'recharge')" href="javascript:;">充值</a>
                </a-menu-item>
                <a-menu-item>
                  <a v-has:agentCutcharge="()=>recharge(record,'cut')" href="javascript:;">扣款</a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </template>
      </a-table>
      <a-pagination :style="{marginTop:'20px',textAlign:'right'}" show-size-changer show-quick-jumper
        :current="search.page" :page-size="search.size" :total="total" @change="pageCurrentChange"
        @showSizeChange="pageSizeChange" :show-total="total => `总数: ${total} `" />
    </a-card>
  </a-layout>
  <a-modal v-model:visible="visible" title="代理商充值" @ok="handleOk" @cancel='price = ""' centered
    :confirm-loading="confirmLoading">
    <a-input-number v-model:value="price" placeholder='请输入要充值的金额' style='width:100%' v-input-filter:price>
    </a-input-number>
  </a-modal>
  <a-modal v-model:visible="visibleCut" title="代理商扣款" @ok="handleCut" @cancel='price = ""' centered
    :confirm-loading="confirmLoading">
    <a-input-number v-model:value="price" placeholder='请输入要扣除的金额' style='width:100%;margin-bottom:20px'
      v-input-filter:price></a-input-number>
    <a-textarea v-model:value="remark" placeholder='请输入备注' :auto-size="{ minRows: 3, maxRows: 5 }" />
  </a-modal>
  <a-modal v-model:visible="visibleRecord" centered :width='1200' :footer="null" title="代理商充值记录" destroyOnClose>
    <recordList :agent_id='agent_id'></recordList>
  </a-modal>
</template>
<script>
  import { DownOutlined } from "@ant-design/icons-vue";
  import { ref, onMounted, reactive, toRefs, inject } from "vue";
  import { message, Model } from "ant-design-vue";
  import { getRouter } from "@/utils/app";
  import { setPageState, getPageState } from "@/utils/pageState";
  import { getAgentList, getAgentLevelList } from "@/api/agent";
  import { setAgentRecharge, setAgentCutcharge } from "@/api/financial";
  import { $iscode } from "@/utils/app";
  import recordList from './components/record'
  // 初始化默认筛选项数值
  let defSearch = {
    keywords: "",
    level_id: undefined,
    page: 1,
    limit: 10,
  };
  export default {
    components: { DownOutlined, recordList },
    setup(props) {
      // // 初始化 分页信息和筛选项信息
      let search = ref({ ...defSearch });
      // 列表数据和列头配置
      let data = ref([]);
      const state = reactive({
        confirmLoading: false,
        listLoading: false,
        visible: false,
        visibleRecord: false,
        visibleCut: false,
        total: 1,
        levelList: [],
        price: '',
        agent_id: '',
        remark: ''
      });
      let columns = [
        {
          title: "公司名称",
          dataIndex: "company",
          key: "company",
        },
        {
          title: "代理商账号",
          dataIndex: "account",
          key: "account",
        },
        {
          title: "代理商级别",
          dataIndex: "level_name",
          key: "level_name",
        },
        {
          title: "账户余额",
          dataIndex: "balance",
          key: "balance",
        },
        {
          title: "操作",
          key: "action",
          slots: { customRender: "action" },
        },
      ];
      const $Modal = inject('$Modal')
      // 页面筛选项搜索
      const pageSearchChange = () => {
        search.value = { ...search.value, page: 1 };
        initData(search.value);
      };
      // 分页当前页切换
      const pageCurrentChange = (page, pageSize) => {
        search.value.page = page;
        initData(search.value);
      };
      // 分页当前页显示多少条切换
      const pageSizeChange = (current, size) => {
        search.value.page = 1;
        search.value.limit = size;
        initData(search.value);
      };

      //代理商等级列表
      const getLevelList = async () => {
        let res = await getAgentLevelList().then(res => res.data);
        if ($iscode(res)) {
          state.levelList = res.data.data
        } else {
          message.error(res.msg);
        }
      }
      //代理商列表
      const initData = async (values) => {
        data.value = []
        state.listLoading = true;
        try {
          let res = await getAgentList(values).then(res => res.data);
          state.listLoading = false;
          if ($iscode(res)) {
            data.value = res.data.data;
            state.total = res.data.total;
          } else {
            message.error(res.msg);
          }
        } catch (e) {
          state.listLoading = false;
        }
      };
      //充值
      const recharge = (e, type) => {
        state.agent_id = e.id
        if (type == 'record') {
          state.visibleRecord = true;
        } else if (type == 'recharge') {
          state.visible = true;
        } else {
          state.visibleCut = true;
        }

      };
      const handleCut = async () => {
        if (state.price == '') {
          message.error('请输入扣款金额');
          return false
        }
        if(state.remark == ''){
          message.error('请输入扣款备注');
          return false
        }
        state.confirmLoading = true
        $Modal.confirm({
          title: '提示',
          content: '您确定要进行扣款操作么?',
          centered:true,
          onOk: async () => {
            let res = await setAgentCutcharge({ agent_id: state.agent_id, number: state.price, mark: state.remark }).then(res => res.data);
            state.confirmLoading = false
            if ($iscode(res)) {
              message.success(res.msg);
              state.visible = false;
              state.visibleCut = false;
              initData(search.value);
              state.price = ''
              state.remark = ''
            } else {
              message.error(res.msg);
            }
          }, cancel: () => {
            return
          }
        })
      }
      const handleOk = async () => {
        if (state.price == '') {
          message.error('请输入充值金额');
          return false
        }
        state.confirmLoading = true
        $Modal.confirm({
          title: '确定充值么',
          content: '确定充值么?',
          centered:true,
          onOk: async () => {
            let res = await setAgentRecharge({ agent_id: state.agent_id, number: state.price }).then(res => res.data);
            if ($iscode(res)) {
              message.success(res.msg);
              state.visible = false;
              initData(search.value);
              state.confirmLoading = false
              state.price = ''
            } else {
              message.error(res.msg);
              state.confirmLoading = false
            }
          }, cancel: () => {
            return
          }
        })

      }
      onMounted(() => {
        // 查看是否有留存状态，有则替换
        let pageState = getPageState(getRouter().route.path);
        if (pageState) {
          search.value = Object.assign(search.value, pageState);
        }
        getLevelList();
        initData(search.value);
      });
      return {
        ...toRefs(state),
        search,
        data,
        columns,
        pageSearchChange,
        pageCurrentChange,
        pageSizeChange,
        recharge,
        handleOk,
        handleCut
      };
    },
  };
</script>